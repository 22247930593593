$(document).ready(function() {

  // RADIO SELECT ACTIONS
  // Adjust which route the form gets sent through to
  $('.btn.project').click(function(){
    $('#contactForm').attr('action','/contact/gcro');
  })
  $('.btn.app').click(function(){
    $('#contactForm').attr('action','/contact');
  })

// Preview profile URL
$('#selectPhoto').change(function(){
  readURL(this);
});

function readURL(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function (e) {
      console.log('image changed in css');
        $('#selectedPhoto').css("background-image", "url(" + e.target.result + ")")
        $('#photoCaptionPreview').css("background-image", "url(" + e.target.result + ")")
        $('#next').addClass('show');
        $('.readonly').removeClass('readonly');
      };

      reader.readAsDataURL(input.files[0]);
      $('#selectedPhoto').addClass('selected');
      $('.buttonText').text('Change Photo');
  }
}

  // INGESTOR MOVING SLATE TOGGLE
  $('#next').on('click', function(){
    $('.moving-slate').toggleClass('shift-left');
    $('.btn_back').attr('onclick',"");
    $('.btn_back').attr('id','previous');
    $(this).addClass('hide');
    $(this).removeClass('show');
    $(window).scrollTop(0);
   })

  $(document).on('click', '#previous', function(){
    $('.moving-slate').toggleClass('shift-left');
    $(this).attr('onclick',"history.back()");
    $(this).attr('id',"");
    $('#next').removeClass('hide');
    $('#next').addClass('show');
   })

  // PHOTO EDIT FUNCTIONS
  $('.photo-ingestor-page.edit .bootstrap-filestyle .buttonText').text('Change Photo');
  $('.photo-ingestor-page.edit #next').addClass('show');


//checks if search input has any value
  // if($('#sb-search-input').attr('value') != ""){
  //   $('#sb-search').addClass('sb-search-open')
  // }
  if($('#sb-search').hasClass('sb-search-open')){
    // $('#sb-search-input').show();
  }
  else{
    $('#sb-search-input').val('');
  }

  // DATERANGE PICKER AND SELECT2 DESKTOP INITIALISATIONS
    if ($(window).width() >= 1140) {  
      $('input[id="date"]').daterangepicker({
        singleDatePicker: true,
        maxDate: new Date(),
        locale: {
          format: 'YYYY-MM-DD'
        }
    })
      $('.art--details').select2();
      $('#date').val('Select a date');
      console.log('desktop view, date picker enabled');
    }  

// NAVBAR COLOR FILL ON SCROLL
// WARNING - this is firing consistantly and may slow some browsers
var navHeight = $('.header--navigation').height();
$(window).scroll(function(){
  if ($(window).scrollTop() >= navHeight) {
    $('.header--navigation').addClass('dropshadow');
  }
  else {
    $('.header--navigation').removeClass('dropshadow');
  }
});
  
  // SIDEBAR NAVIGATION
  $('#hamburger').mousedown(function(){
    $('.navbar-collapse').toggleClass('reveal');
  });
  
  $('#artist_id').select2({
    tags: true,
    multiple: false
  });
  
  $('#collaborators').select2({
      tags: true
  });
  $('#collaborator-photo').select2({
      tags: true
  });
  
  
  // NAVBAR COLOR FILL ON SCROLL
  // WARNING - this is firing consistantly and may slow some browsers
  var navHeight = $('.header--navigation').height();
  $(window).scroll(function(){
    if ($(window).scrollTop() >= navHeight) {
      $('.nav-fade .header--navigation').addClass('fill');
    }
    else {
      $('.nav-fade .header--navigation').removeClass('fill');
    }
  });
  
    // Toggle spinner on click on any spinner enabled button
    $('.btn_spinner').on('click', function(){
      $(this).addClass('show');
      $(this).addClass('disabled');
  })
  
    // Custom Bootstrap Validation
    // Example starter JavaScript for disabling form submissions if there are invalid fields
  (function() {
    'use strict';
    window.addEventListener('load', function() {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.getElementsByClassName('needs-validation');
      // Loop over them and prevent submission
      var validation = Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add('was-validated');
        }, false);
      });
    }, false);
  })();
  
  // new UISearch($('#sb-search'));
  
  // End of document ready function
  });
  